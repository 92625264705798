var exports = {};
/**
 * Localized values for the placeholder string
 * 
 * @private
 */
var placeholder = {
  // list drawn from https://docs.mapbox.com/api/search/#language-coverage
  "de": "Suche",
  // german
  "it": "Ricerca",
  //italian
  "en": "Search",
  // english
  "nl": "Zoeken",
  //dutch
  "fr": "Chercher",
  //french
  "ca": "Cerca",
  //catalan
  "he": "\u05DC\u05D7\u05E4\u05E9",
  //hebrew
  "ja": "\u30B5\u30FC\u30C1",
  //japanese
  "lv": "Mekl\u0113t",
  //latvian
  "pt": "Procurar",
  //portuguese 
  "sr": "\u041F\u0440\u0435\u0442\u0440\u0430\u0433\u0430",
  //serbian
  "zh": "\u641C\u7D22",
  //chinese-simplified
  "cs": "Vyhled\xE1v\xE1n\xED",
  //czech
  "hu": "Keres\xE9s",
  //hungarian
  "ka": "\u10EB\u10D8\u10D4\u10D1\u10D0",
  // georgian
  "nb": "S\xF8ke",
  //norwegian
  "sk": "Vyh\u013Ead\xE1vanie",
  //slovak
  "th": "\u0E04\u0E49\u0E19\u0E2B\u0E32",
  //thai
  "fi": "Hae",
  //finnish
  "is": "Leita",
  //icelandic
  "ko": "\uC218\uC0C9",
  //korean
  "pl": "Szukaj",
  //polish
  "sl": "Iskanje",
  //slovenian
  "fa": "\u062C\u0633\u062A\u062C\u0648",
  //persian(aka farsi)
  "ru": "\u041F\u043E\u0438\u0441\u043A" //russian
};

exports = {
  placeholder: placeholder
};
export default exports;